import React, { useState } from 'react';
import { Modal } from 'antd';
import { useKonami } from 'react-konami-code';

const KcEe = props => {
  const {
    enabled
  }  = props;

  if (!enabled) return null;

  const [isEasterEggOpen, setIsEasterEggOpen] = useState<boolean>(false);

  const easterEgg = () => {
    setIsEasterEggOpen(true)
    document.querySelector('#appLogo')?.classList.add('gone')
    setTimeout(() => {
      document.querySelector('#appLogo').remove();
    }, 1000);
  }

  useKonami(easterEgg, { code: [70,82,69,69,84,69,68,68,89], resetDelay: 2000 })

  return (
    <>
      <Modal
        title="You have freed Teddy!"
        open={isEasterEggOpen}
        onCancel={() => setIsEasterEggOpen(false)}
        footer={false}
      >
        <p>Teddy is no longer a prisoner of this application. He can live a happy life full of new adventures. He thanks you warmly.</p>
        <iframe src="https://giphy.com/embed/Ho8g4735cRmxMCKJuR" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        <div style={{ display: 'flex', justifyContent: 'center', color: '#D3D3D3' }}>
          Designed by 9tq in their office. (Free time realisation)
        </div>
      </Modal>
    </>
  )
}

export default KcEe;